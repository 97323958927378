.container {
    padding-top: 104px;
    padding-left: 400px;
    width: 100%;
    height: 100vh;
    overflow: scroll;
}

.qrcode {
    position: fixed;
    z-index: 10;
    bottom: 64px;
    right: 64px;
    max-width: 80px;
    max-height: 80px;
    padding: 8px;
    border-radius: 8px;
    background-color: #fff;
}

.tools {
    z-index: 5;
    width: 320px;
    margin-right: 16px;

    & [class*="SettingsPanel_panel"] {
        margin-bottom: 16px;
    }

    &Wrapper {
        position: fixed;
        z-index: 10;
        top: 104px;
        left: 32px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    &ArrowButton {
        padding: 6px 2px 4px 4px;
        border-radius: 4px;
        border: 1px solid #c3c3c3;
        background-color: #fff;
        position: fixed;
        z-index: 10;
        top: 104px;
        left: -4px;
        cursor: pointer;
        color: #969696;
    }
}
.caption {
    color: #6b6b6b;
    text-align: left;
    border-bottom: 1px solid #d1d1d1;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px !important;
}

.filter {
    margin-bottom: 24px !important;
}

.items {
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
    
    .chip {
        margin-right: 8px !important;
        margin-bottom: 8px !important;
    
        & svg {
            width: 24px;
            height: 24px;
        }
    }    
}

.items::-webkit-scrollbar {
    display: block;
    width: 4px;
    height: 4px;
  }

  .items::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .items::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #d1d1d1;
    border-right: none;
    border-left: none;
  }

  .items::-webkit-scrollbar-button:vertical:start:increment,
  .items::-webkit-scrollbar-button:vertical:end:decrement,
  .items::-webkit-scrollbar-button:horizontal:start:increment,
  .items::-webkit-scrollbar-button:horizontal:end:decrement {
    display: none;
  }
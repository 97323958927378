.sliderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 520px;
    width: 30px;
    margin-right: 48px;
    z-index: 5;

    [class*="MuiSlider-root"] {
        margin-top: 16px;
        margin-bottom: 16px;
    }
}

.controls {
    cursor: pointer;
}
@import "../../styles/colors.scss";

.box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.card {
    position: relative;
    display: block;
    box-sizing: border-box;
    width: 400px;
    height: auto;
    min-height: 400px;
    padding: 24px;
    background-color: $clr_white;
    border: none;
    border-radius: 20px;

    .devider {
        color: white;
        font-weight: 600;
        border-color: white;
        margin-top: 32px;
    
        &::before {
            border-color: white;
        }
    
        &::after {
            border-color: white;
        }
    }

    &--blue {
        background-color: $clr_blue;
    }

    &--violet {
        background-color: $clr_violet;
    }

    &--green {
        background-color: $clr_green;
    }

    &--pink {
        background-color: $clr_pink;
    }

    &--peach {
        background-color: $clr_peach;
    }

    &--orange {
        background-color: $clr_orange;
    }

    &--blue-light {
        background-color: $clr_blue-light;
    }

    .stars {
        position: absolute;
        width: 100px;
        height: 100px;
        left: 50px;
        top: 120px;
        z-index: 10;
    }
}

.workPosition {
    display: block;
    max-width: 70%;
    color: $clr_white;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 24px;
    text-align: left;
}

.person {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    color: $clr_white;  

    .avatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 5px solid $clr_white;
        margin-bottom: 24px;
    }

    .name {
        display: block;
        width: 100%;      
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 16px;
    }

    .city {
        font-size: 16px;
    }
}

.recomendation {
    position: relative;
    background-color: #ffffff4d;
    color: white;
    padding: 16px;
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;
    border-radius: 24px;

    .arrow {
        position: absolute;
        top: -8px;
        right: 64px;
        width: 0; 
        height: 0; 
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;        
        border-bottom: 8px solid #ffffff4d;
    }

    & span {
        width: 100%;
        display: block;
        margin-bottom: 16px;
        font-weight: 600;
    }
}

.achives {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    padding-top: 16px;

    & img {
        opacity: 0.3;
        width: 56px;
        height: 56px;

        &.active {
            opacity: 1;
        }
    }
}

.stackItems {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 16px;
}
.container {
    position: relative;
    text-align: center;
    overflow: visible;
    transform-origin: left top;
}
  
.map {
  z-index: 0;
  path {
    cursor: pointer;
    fill: #6573c3;
  }
  path:hover {
    fill-opacity: 80%;
  }
  .crtRegion {
      fill: #3f51b5;
  }
}
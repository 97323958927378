.container {
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    @media (max-width: 480px) {
      height: calc(100vh - 140px);
    }
  }
  
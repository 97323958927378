.actionsCell {
    display: flex;

    svg {
        margin-right: 16px;
        cursor: pointer;
        color: #1976d2;
    }
}

.selectLabel {
    font-size: 14px;
    margin-bottom: 4px;
    color: #00000099;
}
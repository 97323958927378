.toolsPanel {
    display: none;
    position: relative;
    background-color: rgba(255, 255, 255, 0.3);
    height: auto;
    border-radius: 16px;
    border: 1px solid #d1d1d1;
    padding: 32px 24px;
}

.toolsPanel.open {
    display: block;
}

.closeButton {
    width: 32px;
    height: 32px;
    padding: 6px;
    position: absolute;
    right: 8px;
    top: 8px;
    color: #6b6b6b;
    border: none;
    cursor: pointer;
}
.panel {
    width: 100%;
    height: auto;   
    margin-bottom: 24px !important;
}

.caption {
    color: #6b6b6b;
    text-align: left;
    border-bottom: 1px solid #d1d1d1;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px !important;
}
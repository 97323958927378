$clr_white: #ffffff;
$clr_black: #000000;
$clr_blue: #3E27F8;
$clr_violet: #933FD3;
$clr_green: #8ED33E;
$clr_pink: #F27CCA;
$clr_peach: #F59F7B;
$clr_orange: #E25F14;
$clr_blue-light: #67BAD6;

$clr_gray: #616161;
$clr_gray_light: #9e9e9e;
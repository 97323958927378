.title {
    margin-top: 24px;
    margin-bottom: 24px !important;
    font-weight: 600 !important;
    color: #3f51b5;
}

.list {
    width: 100%;
    max-width: 420px;
    padding: 16px !important;

    & [class*="MuiAvatar-root"] svg {
        width: 40px;
        height: 40px;
    }
}

.name {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}
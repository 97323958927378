.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    padding: 8px 24px;
    background-color: #eaeaea;
    z-index: 10;

    .logo {
        width: 150px;
        cursor: pointer;
    }

    .profile {
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
        color: #6b6b6b;
        width: 120px;

        svg {
            margin-left: 8px;
        }
    }
}
.container {
    width: 100vw;
    height: 100vh;
    text-align: center;
    vertical-align: middle;
    padding-top: 40vh;
}

.logo {
    cursor: pointer;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    color: #b4b4b4;
    width: 350px;

    svg {
        width: 100%;
        height: 100%;
        path {
            fill: #b4b4b4;
        }
    }
}